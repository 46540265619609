<template>
  <docs-layout>
    <div class="space-y-4 pb-10 w-full text-left">
      <h2 class="text-2xl font-bold">Input radio group</h2>
      <p class="font-bold">Default</p>
      <div class="w-1/4">
        <asom-input-radio-group
          v-model="sampleValue"
          :options="sampleOptions"
        />
      </div>
      <p>Values: {{sampleValue}}</p>
      <p class="font-bold">States</p>
      <div class="w-full space-y-10">
        <asom-input-radio-group
          state="default"
          v-model="value1"
          :options="sampleOptions"
        />
        <asom-input-radio-group
          state="error"
          v-model="value3"
          :options="sampleOptions"
        />
      </div>
      <p class="font-bold">Disabled</p>
      <div class="w-1/4">
        <asom-input-radio-group
          disabled
          v-model="value4"
          :options="sampleOptions"
        />
      </div>
      <p class="font-bold">Horizontal display</p>
      <div class="w-full">
        <asom-input-radio-group
          horizontal
          v-model="value5"
          :options="sampleOptions"
        />
      </div>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout
  },
  data() {
    return {
      sampleValue: '',
      sampleOptions: [
        { value: '1', label: 'Option 1', description: 'Sample description' },
        { value: "2", label: 'Option 2', description: 'Sample description' },
        { value: '3', label: 'Option 3', description: 'Sample description' },
        { value: '4', label: 'Option 4', description: 'Sample description' },
      ],
      value1: '1',
      value2: '1',
      value3: '1',
      value4: '1',
      value5: ''
    }
  },
  methods: {
    onSampleInputChange() {
      this.sampleChecked = !this.sampleChecked
    }
  }
}
</script>